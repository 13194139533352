import { getState } from '@redux/store';
import { Cookies } from 'react-cookie';
import { logoutUser } from '@redux/auth';
import { isAuthenticated } from '@redux/selectors/auth';

export default ({
  endpoint,
  url,
  data = null,
  token = null,
  onSuccess = () => null,
  onFail = () => null,
  hasFile = false,
}) => {
  console.log('calling API  ', endpoint, data);
  const xhr = new XMLHttpRequest();
  const isGet = endpoint ? endpoint[0]?.toLowerCase() === 'get' : url[0]?.toLowerCase() === 'get';
  let urlParams = '';
  if (isGet && !!data) {
    urlParams = `?${ Object.entries(data).map(e => e.join('=')).join('&')}`;
  }
  if (!!data) {
    console.log('data in API call', data);
  }
  if (url) {
    xhr.open(url[0], url[1]);
  } else if (endpoint) {
    xhr.open(endpoint[0], endpoint[1] + urlParams);
  }
  const requestHeaders = {
    Accept: 'application/json',
  };

  if (!hasFile) {
    requestHeaders['Content-Type'] = 'application/json';
  }

  if (!token) {
    const ck = new Cookies();
    const user = ck.get('user');
    if (user && user.token) {
      token = user.token;
    }
  }

  if (token) {
    requestHeaders.Authorization = `Bearer ${token}`;
  }

  for (const header in requestHeaders) {
    xhr.setRequestHeader(header, requestHeaders[header]);
  }

  xhr.onload = ({ target }) => {
    if (target.status === 401) {
      console.log('API failed', target);
      onFail(target);
      if (isAuthenticated(getState())) {
        logoutUser();
      }
      return;
    }
    if (target.status > 299) {
      console.log('API failed', target);
      onFail(target);
      return;
    }

    console.log('API success ', target.responseText);

    if (target.status == 204) {
      onSuccess({});
    } else {
      onSuccess(JSON.parse(target.responseText));
    }
  };

  xhr.onerror = error => {
    console.log('API failed', error);
    onFail(error);
  };

  if (data && !isGet) {
    if (hasFile) {
      const formData = new FormData();
      if (data) {
        console.log('all params');
        for (const key of Object.keys(data)) {
          console.log(key, data[key]);
          formData.append(key, data[key]);
        }
      }
      xhr.send(formData);
    } else {
      xhr.send(JSON.stringify(data));
    }
  } else {
    xhr.send();
  }
};
