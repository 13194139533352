import Api from '@helpers/api';
import { endpoints } from '@configs/endpoints';
import _ from 'lodash';
import { mergeArraysOfObjectsUniqueById } from 'ofo-helpers';
import { dispatch } from './store';

const NAMESPACE = 'annotation';

const UPDATE_DETECTION = `${NAMESPACE}/UPDATE_DETECTION`;
const UPDATE_DETECTION_SUCCESS = `${NAMESPACE}/UPDATE_DETECTION_SUCCESS`;
const UPDATE_DETECTION_FAIL = `${NAMESPACE}/UPDATE_DETECTION_FAIL`;

const DELETE_ANNOTATION = `${NAMESPACE}/DELETE_ANNOTATION`;
const DELETE_ANNOTATION_SUCCESS = `${NAMESPACE}/DELETE_ANNOTATION_SUCCESS`;
const DELETE_ANNOTATION_FAIL = `${NAMESPACE}/DELETE_ANNOTATION_FAIL`;

const RESTORE_ANNOTATION = `${NAMESPACE}/RESTORE_ANNOTATION`;
const RESTORE_ANNOTATION_SUCCESS = `${NAMESPACE}/RESTORE_ANNOTATION_SUCCESS`;
const RESTORE_ANNOTATION_FAIL = `${NAMESPACE}/RESTORE_ANNOTATION_FAIL`;

const GET_ANNOTATION = `${NAMESPACE}/GET_ANNOTATION`;
const GET_ANNOTATION_FAIL = `${NAMESPACE}/GET_ANNOTATION_FAIL`;
const GET_ANNOTATION_SUCCESS = `${NAMESPACE}/GET_ANNOTATION_SUCCESS`;

const RELOAD_ANNOTATION = `${NAMESPACE}/RELOAD_ANNOTATION`;
const RELOAD_ANNOTATION_FAIL = `${NAMESPACE}/RELOAD_ANNOTATION_FAIL`;
const RELOAD_ANNOTATION_SUCCESS = `${NAMESPACE}/RELOAD_ANNOTATION_SUCCESS`;

export const apiUpdateDetections = (data, onSuccess = () => null, onFail = () => null) => {
  dispatch({ type: UPDATE_DETECTION });

  Api({
    endpoint: endpoints.updateAnnotation(data.id),
    data,
    onSuccess: (response) => {
      dispatch({ type: UPDATE_DETECTION_SUCCESS, data: response.data, params: data });
      onSuccess();
    },
    onFail: (error) => {
      onFail();
      dispatch({ type: UPDATE_DETECTION_FAIL, error });
    },
  });
};

export const getAnnotation = (data, isAppendExistingData = false) => {
  dispatch({ type: GET_ANNOTATION, data });

  Api({
    endpoint: endpoints.getAnnotation(),
    data,
    onSuccess: (response) => dispatch({ type: GET_ANNOTATION_SUCCESS, data: response.data, isAppendExistingData }),
    onFail: (error) => dispatch({ type: GET_ANNOTATION_FAIL, error }),
  });
};

export const deleteAnnotation = (id, onSuccess = () => null) => {
  dispatch({ type: DELETE_ANNOTATION, id });
  Api({
    endpoint: endpoints.deleteAnnotation(id),
    onSuccess: (response) => {
      dispatch({ type: DELETE_ANNOTATION_SUCCESS, id });
      onSuccess();
    },
    onFail: (error) => dispatch({ type: DELETE_ANNOTATION_FAIL, error }),
  });
};

export const restoreAnnotation = (id, onSuccess = () => null) => {
  dispatch({ type: RESTORE_ANNOTATION, id });
  Api({
    endpoint: endpoints.restoreAnnotation(id),
    onSuccess: (response) => {
      dispatch({ type: RESTORE_ANNOTATION_SUCCESS, id });
      onSuccess();
    },
    onFail: (error) => dispatch({ type: RESTORE_ANNOTATION_FAIL, error }),
  });
};

export const reloadAnnotation = (data, isAppendExistingData = false) => {
  dispatch({ type: RELOAD_ANNOTATION, data });

  Api({
    endpoint: endpoints.getAnnotation(),
    data,
    onSuccess: (response) => {
      dispatch({ type: RELOAD_ANNOTATION_SUCCESS, data: response.data, isAppendExistingData });
    },
    onFail: (error) => null,
  });
};

const initialState = {
  isLoading: true,
  filters: {},
  data: { data: [] },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DELETE_ANNOTATION:
    case RESTORE_ANNOTATION:
    case UPDATE_DETECTION:
    case RELOAD_ANNOTATION:
      return { ...state, isLoading: true, error: null };
    case UPDATE_DETECTION_SUCCESS:
      const detectionData = state.data;
      detectionData.data = detectionData.data.map((i) => {
        let detection = i;
        if (i.id == action.params.id) {
          detection = { ...detection, ...action.params };
        }
        return detection;
      });
      if (action.params.is_false_detection == 1 || action.params.is_false_detection == 0) {
        detectionData.data = detectionData.data.filter((f) => {
          return f.id != action.params.id;
        });
      }

      return { ...state, isLoading: false, data: detectionData };
    case UPDATE_DETECTION_FAIL:
      return { ...state, isLoading: false, error: action.error };

    case GET_ANNOTATION:
      return {
        ...state, isLoading: true, error: null, filters: action.data,
      };
    case GET_ANNOTATION_SUCCESS: {
      const newData = action.data;
      newData.data = _.uniqBy(action.data.data, 'id');
      if (!state.filters.page && !action.isAppendExistingData) return { ...state, isLoading: false, data: newData };

      const combinedData = action.data;
      combinedData.data = mergeArraysOfObjectsUniqueById(state.data.data, action.data.data);
      combinedData.data.sort((a, b) => parseInt(b.id) - parseInt(a.id));
      return { ...state, isLoading: false, data: combinedData };
    }
    case DELETE_ANNOTATION_FAIL:
    case RESTORE_ANNOTATION_FAIL:
    case GET_ANNOTATION_FAIL:
    case RELOAD_ANNOTATION_FAIL:
      return { ...state, isLoading: false, error: action.error };

    case RESTORE_ANNOTATION_SUCCESS:
    case DELETE_ANNOTATION_SUCCESS: {
      const initialData = state.data;
      initialData.data = initialData.data.filter((i) => i.id != action.id);
      return { ...state, isLoading: false, data: initialData };
    }
    case RELOAD_ANNOTATION_SUCCESS: {
      const newData = action.data;
      newData.data = _.uniqBy(action.data.data, 'id');
      if (!state.filters.page && !action.isAppendExistingData) return { ...state, isLoading: false, data: newData };

      const combinedData = action.data;
      combinedData.data = mergeArraysOfObjectsUniqueById(state.data.data, action.data.data);
      combinedData.data.sort((a, b) => parseInt(b.id) - parseInt(a.id));
      return { ...state, isLoading: false, data: combinedData };
    }
    default:
      return state;
  }
};
