import { Cookies } from 'react-cookie';
import { getLoggedInUser } from '@helpers/authUtils';
import Api from '@helpers/api';
import { endpoints, CLIENT_SECRET, CLIENT_ID } from '@configs/endpoints';
import { dispatch } from './store';
import { apiGetProfile } from './action/auth';

export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILED = 'REGISTER_USER_FAILED';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGET_PASSWORD = 'FORGET_PASSWORD';
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
export const FORGET_PASSWORD_FAILED = 'FORGET_PASSWORD_FAILED';

export const loginUser = ({
  username, password, history, redirect_url,
}) => {
  dispatch({ type: LOGIN_USER });
  Api({
    endpoint: endpoints.getToken(),
    data: {
      username,
      password,
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
      grant_type: 'password',
    },
    onSuccess: response => {
      const token = response;

      apiGetProfile({
        token,
        onSuccess: response => {
          const user = response.data;
          dispatch({
            type: LOGIN_USER_SUCCESS,
            payload: user,
          });

          (new Cookies()).set('user', JSON.stringify({
            id: user.id,
            image: user.image,
            companies: user.companies,
            // TODO: why fail to save departments to cookie
            // departments: user.departments,
            username,
            name: user.name,
            role: user.role,
            token: token.access_token,
            refresh_token: token.refresh_token,
            access_annotations_type_list: user.access_annotations_type_list,
            email: user.email,
          }), { path: '/' });

          if (!!redirect_url) {
            history.push(redirect_url);
          } else {
            history.push('/home');
          }
        },
        onFail: response => {
          let error = 'Sorry, please try again later';
          if (response.status === 400) {
            error = 'Sorry, wrong user name or password';
          }
          dispatch({ type: LOGIN_USER_FAILED, payload: error });
        },
      });
    },
    onFail: response => {
      let error = 'Sorry, please try again later';
      if (response.status === 400) {
        error = 'Sorry, wrong user name or password';
      }
      dispatch({ type: LOGIN_USER_FAILED, payload: error });
    },
  });
};

export const loginUserSuccess = (user) => ({
  type: LOGIN_USER_SUCCESS,
  payload: user,
});

export const loginUserFailed = (error) => ({
  type: LOGIN_USER_FAILED,
  payload: error,
});

export const registerUser = (fullname, email, password) => ({
  type: REGISTER_USER,
  payload: { fullname, email, password },
});

export const registerUserSuccess = (user) => ({
  type: REGISTER_USER_SUCCESS,
  payload: user,
});

export const registerUserFailed = (error) => ({
  type: REGISTER_USER_FAILED,
  payload: error,
});

export const logoutUser = (history) => {
  dispatch({ type: LOGOUT_USER });
  (new Cookies()).remove('user');
  if (!history) {
    window.location = '/login';
  } else {
    history.push('/login');
  }
};

export const forgetPassword = (username) => ({
  type: FORGET_PASSWORD,
  payload: { username },
});

export const forgetPasswordSuccess = (passwordResetStatus) => ({
  type: FORGET_PASSWORD_SUCCESS,
  payload: passwordResetStatus,
});

export const forgetPasswordFailed = (error) => ({
  type: FORGET_PASSWORD_FAILED,
  payload: error,
});

const INIT_STATE = {
  user: getLoggedInUser(),
  loading: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, loading: true, error: null };
    case LOGIN_USER_SUCCESS:
      return {
        ...state, user: action.payload, loading: false, error: null,
      };
    case LOGIN_USER_FAILED:
      return { ...state, error: action.payload, loading: false };
    case REGISTER_USER:
      return { ...state, loading: true };
    case 'SET_PROFILE':
      return { ...state, user: action.payload };
    case REGISTER_USER_SUCCESS:
      return {
        ...state, user: action.payload, loading: false, error: null,
      };
    case REGISTER_USER_FAILED:
      return { ...state, error: action.payload, loading: false };
    case LOGOUT_USER:
      return { ...state, user: null };
    case FORGET_PASSWORD:
      return { ...state, loading: true };
    case FORGET_PASSWORD_SUCCESS:
      return {
        ...state, passwordResetStatus: action.payload, loading: false, error: null,
      };
    case FORGET_PASSWORD_FAILED:
      return { ...state, error: action.payload, loading: false };
    default: return { ...state };
  }
};
