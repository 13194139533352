import { db } from '@configs/firebaseConfig';
import { isCameraLive } from '@helpers';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

export default () => {
  const { camera_id } = useParams();
  const [dashcam, set_dashcam] = useState({});
  const [isLive, set_isLive] = useState(false);
  const [refreshDashcam, setRefreshDashcam] = useState(0);

  useEffect(() => {
    db.ref('dashcams').on('value', snapshot => {
      const response = snapshot.val();
      if (!response || Object.keys(response).length == 0) {
        console.log('no response');
        return;
      }
      let isSame = true;
      const keys = Object.keys(response);

      if (keys.length == Object.keys(dashcam).length) {
        for (let i = 0; i < keys.length; i++) {
          if (response[keys[i].ip] != dashcam[keys[i].ip]) {
            isSame = false;
            break;
          }
        }
        if (isSame) return;
      }

      const filteredResponse = {};
      keys.forEach(k => {
        if (!!response[k]?.camera_id) {
          filteredResponse[k] = response[k];
        }
      });
      set_dashcam(filteredResponse[camera_id]);
    });
  }, []);

  useEffect(() => {
    if (dashcam) {
      const interval = setInterval(() => {
        setRefreshDashcam(pv => pv + 1);
      }, 10000);
      return () => clearInterval(interval);
    }
  }, [dashcam]);

  useEffect(() => {
    if (dashcam) set_isLive(isCameraLive(dashcam));
  }, [refreshDashcam, dashcam]);

  return {
    dashcam,
    isLive,
  };
};
