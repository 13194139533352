import Api from '@helpers/api';
import { endpoints } from '@configs/endpoints';
import { dispatch } from './store';

const NAMESPACE = 'assets';

export const GET_ASSET_SUMMARY = `${NAMESPACE}/GET_ASSET_SUMMARY`;
export const GET_ASSET_SUMMARY_SUCCESS = `${NAMESPACE}/GET_ASSET_SUMMARY_SUCCESS`;
export const GET_ASSET_SUMMARY_FAIL = `${NAMESPACE}/GET_ASSET_SUMMARY_FAIL`;

export const NEW_ASSETS_LIST = `${NAMESPACE}/NEW_ASSETS_LIST`;

export const apiGetAssetSummary = id => {
  dispatch({ type: GET_ASSET_SUMMARY });
  Api({
    endpoint: endpoints.getAssetSummary(id),
    onSuccess: ({ data }) => {
      dispatch({ type: GET_ASSET_SUMMARY_SUCCESS, data, id });
    },
    onFail: () => dispatch({ type: GET_ASSET_SUMMARY_FAIL }),
  });
};
const initialState = {
  isLoading: true,
  data: [],
};

export default (state = initialState, action) => {
  const { data } = state;
  switch (action.type) {
    case GET_ASSET_SUMMARY:
      return { ...state, isLoading: true };
    case GET_ASSET_SUMMARY_SUCCESS:
      const newData = data.map(d => {
        const item = { ...d };
        if (d.id == action.id) {
          item.summary = action.data;
        }
        return item;
      });
      return { ...state, isLoading: false, data: newData };
    case GET_ASSET_SUMMARY_FAIL:
      return { ...state, isLoading: false };
    case NEW_ASSETS_LIST:
      return { ...state, data: [...action.data], isLoading: false };
    default:
      return state;
  }
};
