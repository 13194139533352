const NAMESPACE = 'companies';

export const API_GET_COMPANIES = `${NAMESPACE}/API_GET_COMPANIES`;
export const API_GET_COMPANIES_FAIL = `${NAMESPACE}/API_GET_COMPANIES_FAIL`;
export const API_GET_COMPANIES_SUCCESS = `${NAMESPACE}/API_GET_COMPANIES_SUCCESS`;

const initialState = {
  isLoading: false,
  data: [],
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case API_GET_COMPANIES:
      return { ...state, isLoading: true, error: null };
    case API_GET_COMPANIES_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case API_GET_COMPANIES_SUCCESS:
      return { ...state, isLoading: false, data: action.data };
    default:
      return state;
  }
};
