import Api from '@helpers/api';
import { endpoints } from '@configs/endpoints';
import { dispatch } from './store';

const NAMESPACE = 'digitalAssistStatic';

const GET_STATIC = `${NAMESPACE}/GET_STATIC`;
const GET_STATIC_FAIL = `${NAMESPACE}/GET_STATIC_FAIL`;
const GET_STATIC_SUCCESS = `${NAMESPACE}/GET_STATIC_SUCCESS`;

export const getDigitalAssistStatic = () => {
  dispatch({
    type: GET_STATIC,
  });

  Api({
    endpoint: endpoints.digitalAssistStaticData(),
    onSuccess: response => {
      dispatch({
        type: GET_STATIC_SUCCESS,
        data: response.data,
      });
    },
    onFail: error => dispatch({
      type: GET_STATIC_FAIL,
      error,
    }),
  });
};

const initialState = {
  isLoading: false,
  data: {},
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_STATIC_SUCCESS:
      return {
        ...state, isLoading: false, data: { ...action.data },
      };
    case GET_STATIC:
      return {
        ...state, isLoading: true, error: null,
      };
    case GET_STATIC_FAIL:
      return {
        ...state, isLoading: false, error: action.error,
      };
    default:
      return state;
  }
};
