import moment from 'moment';
import _ from 'lodash';

export const isCameraLive = cam => {
  if (!cam) return false;
  if (!cam.recorded_at) return false;
  const diff = moment.duration(moment().diff(moment(cam.recorded_at)));
  return diff.asSeconds() < 90;
};

export const FetchDashcam = async (cam, isLive, setIsStreaming) => {
  if (!cam) return false;
  if (!cam.ip) return false;
  if (!isLive) return false;
  const controller = new AbortController();
  const signal = controller.signal;
  await fetch(`${cam.ip}/blackvue_live.cgi`, { signal })
    .then((response) => {
      controller.abort();
      return setIsStreaming(response.ok);
    })
    .catch((error) => {
      console.log('error', cam?.platno, error);
      return setIsStreaming(false);
    });
};

export const cleanURL = (url) => {
  url = url.split('?')[0];
  if (url.substr(-1) === '/') {
    url = url.slice(0, -1);
  }
  return url;
};

export const getTenant = () => {
  // const domain = window.location.hostname;
  // const details = {
  //   type: 'raise',
  //   name: 'RAISE',
  //   tagline: 'Road Asset Intelligence System Evolution',
  // };
  // if (domain.includes('prolintas')) {
  //   details.type = 'prolintas';
  //   details.name = 'PROLINTAS';
  //   details.tagline = 'Smart Surveillance System';
  // }
  const details = {
    type: 'prolintas',
    name: 'PROLINTAS',
    tagline: 'Smart Surveillance System',
  };
  return details;
};

// export const createFile = async (url) => {
//   const spliturl = url.split('/');
//   const name = spliturl[spliturl.length - 1];
//   console.log('name', name, url, url);
//   const blob = await (await fetch(url)).blob();
//   const metadata = { type: 'image/jpeg' };
//   return new File([blob], name, metadata);
// };

export const getBlobFromUrl = (myImageUrl) => {
  return new Promise((resolve, reject) => {
    const request = new XMLHttpRequest();
    request.open('GET', myImageUrl, true);
    // request.setRequestHeader('Access-Control-Allow-Methods', 'GET, HEAD');
    // request.setRequestHeader('Access-Control-Allow-Origin', '*');
    request.setRequestHeader('Content-Type', 'text/plain');
    request.setRequestHeader('Accept', 'image/avif,image/webp,image/apng,image/svg+xml,image/*,*/*;q=0.8');
    // request.setRequestHeader('Sec-Fetch-Dest', 'empty');
    // request.setRequestHeader('Sec-Fetch-Mode', 'cors');
    // request.setRequestHeader('Sec-Fetch-Site', 'cross-site');
    request.setRequestHeader('pragma', 'no-cache');
    request.responseType = 'blob';
    request.onload = () => {
      resolve(request.response);
    };
    request.onerror = reject;
    request.send();
  });
};

export const toUTC = (time) => {
  return moment(time).subtract(8, 'hours');
};

export const searchItems = (arr, keys, query) => _.filter(arr, (o) => {
  const ifAny = keys.map((attr, idx) => (query[idx] === 'all' ? true : String(o[attr]).toLowerCase().indexOf(String(query[idx]).toLowerCase()) !== -1));
  return ifAny.filter(Boolean).length === keys.length;
});

export const copyToClipBoard = async copyMe => {
  navigator.clipboard.writeText(copyMe);
};