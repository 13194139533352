export const URL = process.env.REACT_APP_URL;
export const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const URL_DA = process.env.REACT_APP_URL_DA;

export const endpoints = {
  getToken: () => ['POST', `${URL }oauth/token`],
  getProfile: () => ['GET', `${URL }api/profile`],
  getNotification: () => ['GET', `${URL }api/notification`],
  updateAnnotation: (id) => ['POST', `${URL }api/annotations/${ id}`],
  getAnnotation: () => ['GET', `${URL }api/annotations`],
  getAssetSummary: (id) => ['GET', `${URL }api/asset-summary/${ id}`],
  getRemoteConfig: () => ['GET', `${URL }api/remote-config`],
  updateRemoteConfig: () => ['POST', `${URL }api/remote-config/update`],
  getStatistics: () => ['GET', `${URL }api/statistics`],
  getPointCloudsForAsset: (assetId) => ['GET', `${URL }api/assets/${assetId}/point-clouds`],
  getRealityMeshesForAsset: (assetId) => ['GET', `${URL }api/assets/${assetId}/reality-meshes`],
  createInspection: (assetId) => ['POST', `${URL }api/assets/${assetId}/aerial-inspections`],
  createReport: () => ['POST', `${URL }api/report`],
  deleteReport: (id) => ['DELETE', `${URL }api/report/${ id}`],
  deleteAnnotation: (id) => ['DELETE', `${URL }api/annotations/${ id}`],
  createArchiveRequest: () => ['POST', `${URL }api/archive`],
  uploadInspectionMedia: (inspectionId) => ['POST', `${URL }api/aerial-inspections/${inspectionId}/inspection-medias`],
  getInspection: (inspectionId) => ['GET', `${URL }api/aerial-inspections/${inspectionId}`],
  getReports: () => ['GET', `${URL }api/report`],
  getActivityLogs: () => ['GET', `${URL }api/activity-log`],
  getUserActivityLogs: (user_id) => ['GET', `${URL }api/activity-log-user/${user_id}`],
  getUserVehicleLogs: (user_id) => ['GET', `${URL_DA }perondavehicle/${user_id}`],
  updateInspectionMedia: (mediaId) => ['POST', `${URL }api/inspection-medias/${mediaId}`],
  getUsers: () => ['GET', `${URL }api/user`],
  getUser: (user_id) => ['GET', `${URL }api/user/${user_id}`],
  addUser: () => ['POST', `${URL }api/user`],
  deleteUser: (user_id) => ['DELETE', `${URL }api/user/${user_id}`],
  getPageAccess: () => ['GET', `${URL }api/page-access`],
  updateUser: (user_id) => ['POST', `${URL }api/user/${user_id}/update`],
  updateUserPageAccess: (user_id) => ['POST', `${URL }api/user/${user_id}/update-page-access`],
  updateUserAccessDetections: (user_id) => ['POST', `${URL }api/user/${user_id}/update_access_annotation`],
  restoreAnnotation: (id) => ['POST', `${URL }api/annotations/restore?annotation_ids=${id}`],
  getLocations: () => ['GET', `${URL }api/locationareas`],
  uploadKml: () => ['POST', `${URL }api/kml`],
  getKml: () => ['GET', `${URL }api/kml`],

  // Digital Asist Endpoints
  getFeedback: () => ['GET', `${URL}api/digital-assist/feedback`],
  deleteFeedback: (id) => ['DELETE', `${URL_DA}feedback/${id}`],
  getOffer: () => ['GET', `${URL}api/digital-assist/offer`],
  createOffer: () => ['POST', `${URL_DA}offer`],
  deleteOffer: (id) => ['DELETE', `${URL_DA}offer/${id}`],
  updateOffer: () => ['POST', `${URL_DA}offer-update`],
  pushNotificationOffer: (id) => ['GET', `${URL_DA}offernotifyusers/${id}`],
  pushNotificationOfferPublic: (id) => ['GET', `${URL_DA}offernotifyusers/${id}?is_production=1`],
  getSos: () => ['GET', `${URL}api/digital-assist/sos`],
  createSos: () => ['POST', `${URL}api/digital-assist/sos`],
  assignSos: (id) => ['POST', `${URL_DA}sos/${id}`],
  updateRondaan: (id) => ['POST', `${URL_DA}rondaan/${id}`],
  getRondaan: () => ['GET', `${URL_DA}rondaan`],
  digitalAssistStaticData: () => ['GET', `${URL_DA}staticdata`],
  getSosStatistics: () => ['GET', `${URL_DA}statistic`],
  getUsersDa: () => ['GET', `${URL_DA}user`],
  getRelease: () => ['GET', `${URL_DA}release`],
};
