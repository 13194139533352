import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { isAuthenticated } from '@redux/selectors/auth';
import { apiGetProfile } from '@redux/action/auth';
import { getDigitalAssistStatic } from '@redux/digitalAssistStatic';

import useFirebaseDashcamHook from '@hooks/firebaseDashcam';
import externalDashcam from '@pages/externalDashcam';

// lazy load all the views
const Home = React.lazy(() => import('@pages/home'));
const Settings = React.lazy(() => import('@pages/settings'));
const Report = React.lazy(() => import('@pages/report'));
const NotFound = React.lazy(() => import('@pages/notfound'));
const Login = React.lazy(() => import('@pages/auth/Login'));
const Logout = React.lazy(() => import('@pages/auth/Logout'));
const Feedback = React.lazy(() => import('@pages/feedback'));
const Summary = React.lazy(() => import('@pages/summary'));
const POI = React.lazy(() => import('@pages/poi'));
const Offers = React.lazy(() => import('@pages/offers'));
const Billboards = React.lazy(() => import('@pages/billboards'));
const Users = React.lazy(() => import('@pages/users'));
const Ticket = React.lazy(() => import('@pages/ticket'))
const EditProfile = React.lazy(() => import('@pages/userProfile'));
const UserManagement = React.lazy(() => import('@pages/userManagement'));
const DashcamMonitoring = React.lazy(() => import('@pages/DashcamMonitoring'));
const Dashcam = React.lazy(() => import('@pages/dashcam'));
const UploadKml = React.lazy(() => import('@pages/uploadKml'));

// handle auth and authorization
const PrivateRoute = ({
  component: Component, roles, user, isAuthenticated, ...rest
}) => {
  const { companies } = useSelector((s) => s);
  useFirebaseDashcamHook();
  React.useEffect(() => {
    getDigitalAssistStatic();
    if (isAuthenticated && !companies.isLoading && companies.data.length == 0) {
      apiGetProfile();
    }
  }, [isAuthenticated]);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isAuthenticated) {
          // not logged in so redirect to login page with the return url
          return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
        }

        // check if route is restricted by role
        if (roles && roles.indexOf(user.role) === -1) {
          // role not authorised so redirect to home page
          return <Redirect to={{ pathname: '/' }} />;
        }

        // authorised so return component
        return <Component {...props} />;
      }}
    />
  );
};

const PrivateRouteWithRedux = connect((state) => ({
  user: state.auth.user,
  isAuthenticated: isAuthenticated(state),
}))(PrivateRoute);

const routes = [
  // does not require token
  {
    path: '/login',
    name: 'Login',
    exact: true,
    component: Login,
    route: Route,
  },
  {
    path: '/logout',
    name: 'Logout',
    exact: true,
    component: Logout,
    route: Route,
  },
  {
    path: '/dashcam-webview/:camera_id',
    name: 'Dashcam Webview',
    exact: true,
    component: externalDashcam,
    route: Route,
  },
  {
    path: '/home',
    name: 'Home',
    exact: true,
    component: Home,
    route: PrivateRouteWithRedux,
    roles: ['hq', 'branch'],
  },
  {
    path: '/report',
    name: 'Report',
    exact: true,
    component: Report,
    route: PrivateRouteWithRedux,
    roles: ['hq', 'branch'],
  },
  {
    path: '/settings',
    name: 'Settings',
    exact: true,
    component: Settings,
    roles: ['hq', 'branch'],
    route: PrivateRouteWithRedux,
  },
  {
    path: '/summary',
    name: 'Summary',
    exact: true,
    component: Summary,
    roles: ['hq', 'branch'],
    route: PrivateRouteWithRedux,
  },
  {
    path: '/feedback',
    name: 'Feedback',
    exact: true,
    component: Feedback,
    roles: ['hq', 'branch'],
    route: PrivateRouteWithRedux,
  },
  {
    path: '/poi',
    name: 'POI',
    exact: true,
    component: POI,
    roles: ['hq', 'branch'],
    route: PrivateRouteWithRedux,
  },
  {
    path: '/offers',
    name: 'Offers',
    exact: true,
    component: Offers,
    roles: ['hq', 'branch'],
    route: PrivateRouteWithRedux,
  },
  {
    path: '/advertisements',
    name: 'Advertisements',
    exact: true,
    component: Billboards,
    roles: ['hq', 'branch'],
    route: PrivateRouteWithRedux,
  },
  {
    path: '/users',
    name: 'Users',
    exact: true,
    component: Users,
    roles: ['hq', 'branch'],
    route: PrivateRouteWithRedux,
  },
  {
    path: '/ticket',
    name: 'Ticket',
    exact: true,
    component: Ticket,
    roles: ['hq', 'branch'],
    route: PrivateRouteWithRedux,
  },
  {
    path: '/user/:user_id',
    name: 'User Profile',
    exact: true,
    component: EditProfile,
    roles: ['hq', 'branch'],
    route: PrivateRouteWithRedux,
  },
  {
    path: '/user',
    name: 'UserManagement',
    exact: true,
    component: UserManagement,
    roles: ['hq', 'branch'],
    route: PrivateRouteWithRedux,
  },
  {
    path: '/dashcam',
    name: 'DashcamMonitoring',
    exact: true,
    component: DashcamMonitoring,
    roles: ['hq', 'branch'],
    route: PrivateRouteWithRedux,
  },
  {
    path: '/dashcam-monitoring',
    name: 'Dashcam',
    exact: true,
    component: Dashcam,
    roles: ['hq', 'branch'],
    route: PrivateRouteWithRedux,
  },
  {
    path: '/upload-kml',
    name: 'UploadKml',
    exact: true,
    component: UploadKml,
    roles: ['hq', 'branch'],
    route: PrivateRouteWithRedux,
  },
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/home" />,
    route: PrivateRouteWithRedux,
  },
  {
    component: () => <NotFound />,
    route: Route,
  },
];

export { routes, PrivateRouteWithRedux };
