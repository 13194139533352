import firebase from 'firebase';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyD6J9h5YKfpG9nyB51FJtt7kUZQi7A2qU4',
  authDomain: 'smart-surveillance-syste-9d4be.firebaseapp.com',
  databaseURL: 'https://smart-surveillance-syste-9d4be.firebaseio.com',
  projectId: 'smart-surveillance-syste-9d4be',
  storageBucket: 'smart-surveillance-syste-9d4be.appspot.com',
  messagingSenderId: '406912002914',
  appId: '1:406912002914:web:499fa493307bcc083b9a3b',
  measurementId: 'G-9Y8H6RS10V',
};
const firebaseApp = firebase.initializeApp(firebaseConfig);
export const db = firebaseApp.database();
export const storage = firebaseApp.storage();
