import { useEffect } from 'react';
import { db } from '@configs/firebaseConfig';
import { dispatch } from '@redux/store';

export default () => {
  useEffect(() => {
    db.ref('dashcams').on('value', (snapshot) => {
      const data = snapshot.val();
      if (!data || Object.keys(data).length === 0) {
        console.log('no response');
        return;
      }
      const cleanData = [{}];// must start with null first index since camera id must same with index. eg: camera id 1 = index 1, therefore index 0 must have dummy null object
      const keys = Object.keys(data);
      keys.forEach(k => {
        if (!!data[k]?.camera_id) {
          cleanData.push(data[k]);
        }
      });
      dispatch({ type: 'dashcams/SET_DASHCAMS', data: cleanData });
    });
    return () => db.ref('dashcams').off('value');
  }, []);
};
