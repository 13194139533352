import { dispatch } from './store';

const NAMESPACE = 'document-categories';

export const UPDATE_VALUE = `${NAMESPACE}/UPDATE_VALUE`;

const initialState = {
  isLoading: false,
  data: [
    { value: 1, label: 'Planning' },
    { value: 2, label: 'Development' },
    { value: 3, label: 'Construction' },
    { value: 4, label: 'Operation & Maintenance' },
    { value: 5, label: 'Decommission' },
  ],
};

export const setDocumentCategories = (data) => dispatch({ type: UPDATE_VALUE, data });

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_VALUE:
      return { ...state, data: action.data };
    default:
      return state;
  }
};
