import React from 'react';
import ImageRerender from 'react-rerender-image';
import low_connectivity from '@assets/images/low_connectivity.png';
import offline from '@assets/images/offline.png';
import useHook from './hook';

const externalDashcam = () => {
  const h = useHook();
  return (
    <>
      {!h.dashcam ? (
        <div
          style={{
            display: 'flex', justifyContent: 'center', width: '100vw',
          }}
        >Camera Not Found
        </div>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <ImageRerender
            key={h.dashcam?.ip}
            src={`${h.dashcam?.ip}/blackvue_live.cgi#${new Date().getSeconds()}`}
            style={{ height: '100vh', width: '100vw' }}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = h.isLive ? low_connectivity : offline;
            }}
          />
        </div>
      )}
    </>
  );
};

export default externalDashcam;
